import React from 'react';

export default {
  pages: {
    floorplans: 'floorplans',
    interiors: 'interiors',
    amenities: 'amenities',
    neighbourhood: 'neighbourhood',
    gallery: 'gallery',
    developer: 'developer',
    register: (
      <>
        register <span className="hide-on-mobile">now</span>
      </>
    ),
  },
  bookAppointment: (
    <>
      Book
      <br /> Appointment
    </>
  ),
  backButton: 'Back to Precidia',
  home: {
    hero: {
      script: 'Elevate Your Living',
      title:
        'Rising 29 storeys, Precidia presents a boutique collection of 178 one-bedroom and den to three-bedroom homes in the heart of West Coquitlam.',
      text: 'At the intersection of tranquil living and urban convenience, Precidia is a harmonious blend of everything you need for the day-to-day, situated on the quiet, yet connected residential street of Cottonwood Avenue and Whiting Way. ',
      button: 'View Floorplans',
    },
    content1: {
      title: 'Your Retreat',
      text: 'From sleek features and fixtures, to state-of-the-art appliances, Ledingham McAllister homes are renowned for their exceptional quality and value, everytime.',
      button: 'Interiors',
    },
    content2: {
      title: (
        <>
          Designed With <br />
          Every Detail in Mind
        </>
      ),
      text: 'From the grand arrival experience to the thoughtfully curated amenity spaces throughout, we are on a never-ending mission to give our homeowners more. ',
      button: 'Amenities',
    },
    content3: {
      title: (
        <>
          Quiet Yet
          <br />
          Connected
        </>
      ),
      text: 'Precidia is a harmonious blend of everything you need for the day-to-day, situated on a quiet, yet connected residential street. Steps to the SkyTrain, shopping, restaurants, and more, Precidia is perfectly placed for work, wellness, and everything in between.',
      button: 'Neighbourhood',
    },
    backHome: 'Back to homepage',
  },
  popup: {
    title: 'Save 3%',
    text1: 'on all homes',
    text2: 'and own with ',
    title2: '15% down*',
    button: 'Register Now',
  },
  floorplans: {
    title: (
      <>
        INTIMATE COLLECTION OF 178 <br />
        ONE-BEDROOM AND DEN TO THREE-BEDROOM HOMES
      </>
    ),
    header: {
      plan: 'Plan',
      type: 'Type',
      sqft: 'Aprox. Sq Ft',
    },
    types: {
      oneBedOneDen: 'One Bedroom & Den',
      twoBed: 'Two Bedroom',
      threeBed: 'Three Bedroom',
    },
    downloadPlan: 'Download Plan',
    bed: 'bedroom',
    den: 'den',
    bath: 'bath',
    indoor: 'indoor living',
    outdoor: 'outdoor living',
    approx: 'approx.',
    total: 'total',
    back: '<  BACK TO ALL FLOORPLANS',
  },
  interiors: {
    hero: {
      title: 'YOUR RETREAT',
      intro:
        'Come home to elevated living at Precidia. A boutique collection of one-bedroom and den to three-bedroom homes designed with every detail in mind. Homes at Precidia boast open-concept floor plans with over-height ceilings and expansive windows framing picturesque views from the North Shore mountains to the Fraser River.',
      body: (
        <>
          <p className="body">9’ ceilings and air conditioning throughout</p>
          <p className="body">
            Modern kitchens with quartz composite countertops and contemporary
            Carrara-inspired porcelain tile backsplash
          </p>
          <p className="body">
            Cook and entertain with full-sized stainless steel appliances
            including a five-burner gas cooktop
          </p>
          <p className="body">
            Full-sized, front-loading, energy-efficient Whirlpool washer and
            dryer
          </p>
          <p className="body">
            Spa-inspired bathrooms with stunning floating vanities featuring
            quartz composite countertops and sleek under-cabinet lighting
          </p>
          <p className="body">
            Generous covered balconies for seamless outdoor living
          </p>
        </>
      ),
    },
    banner: {
      title: 'timeless finishes',
      body: 'Available in two carefully curated designer colour schemes to align with your ever-changing life and style. Choose between the bright and modern Chestnut scheme, or the more contemporary and neutral Ivory scheme.',
      drag: 'drag',
      dark: 'dark scheme: chestnut',
      light: 'light scheme: ivory',
    },
    banner2: {
      text: 'From sleek features and fixtures, to state-of-the-art appliances, Ledingham McAllister homes are renowned for their exceptional quality and value, everytime.',
      buttonLabel: 'Download features sheet',
    },
  },
  amenities: {
    hero: {
      title: 'Designed With Every Detail in Mind',
      intro:
        'Working with the award-winning IBI Group, Precidia was meticulously designed with every detail in mind, from the grand arrival experience to the thoughtfully curated amenity spaces throughout the building. We are on a never-ending mission to give our homeowners more. In the heart of West Coquitlam, Precidia contributes to an unparalleled living experience.',
      body: (
        <>
          <p className="body">Architecture by award-winning IBI Group</p>
          <p className="body">
            Extravagant arrival with expansive auto court, magnificent water
            features, and beautifully manicured gardens
          </p>
          <p className="body">
            Dramatic over-height lobby with luxurious fireside lounge
          </p>
          <p className="body">Dedicated concierge desk awaiting your arrival</p>
          <p className="body">Secured visitor parking</p>
          <p className="body">Guest suite for out-of-town visitors</p>
        </>
      ),
    },
    banner1: {
      title: 'STEP UP YOUR LIFESTYLE',
      text: 'Precidia is complemented by 10,000 sq. ft. of fully programmed amenities to take your lifestyle to the next level. Just an elevator ride away, you’ll have access to a full floor designed to host and entertain (even overnight visitors) with our impressive amenity level and fully furnished guest suite.',
    },
    banner2: {
      title: (
        <>
          Why leave when <br />
          you can stay?
        </>
      ),
      body: (
        <>
          <p className="body">
            Warm and inviting social club with fireside lounge, billiards table,
            fully-equipped chef’s kitchen, and separate intimate dining room
          </p>
          <p className="body">
            Rooftop Terrace featuring fireside lounge, dining, and children’s
            play area{' '}
          </p>
          <p className="body">Fully-equipped Fitness Centre</p>
          <p className="body">
            Multi-purpose rooms equipped for parcel delivery, bike storage, and
            repair area
          </p>
          <p className="body">Dog wash station</p>
        </>
      ),
    },
    banner3: {
      title: 'Come home to elevated living',
    },
    ground: 'ground',
    lobby: 'lobby',
    amenity: 'amenity',
    lobbyEntrance: 'Lobby Entrance',
    rooftop: 'Amenity Level Rooftop',
    autoCourt: 'Auto Court Entrance',
    grandLobbyEntrance: 'Grand Lobby Entrance',
    concierge: 'Concierge',
    fitnessCentre: 'Fitness Centre',
    kitchen: 'Chef’s Kitchen',
    billards: 'Billards',
    outdoorLounge: 'Outdoor Lounge',
  },
  neighbourhood: {
    hero: {
      title: 'Quiet Yet Connected',
      subtitle:
        'Situated on the residential street of Cottonwood Avenue and Whiting Way in the heart of West Coquitlam, Precidia is conveniently located to satisfy your every desire.',
    },
    script: 'Enchanting Backyard',
    banner1: {
      text: 'Steps to the SkyTrain, shopping, restaurants, and more, Precidia is perfectly placed for work, wellness, and everything in between. ',
    },
    banner2: {
      text: 'For the enthusiast or the seeker of serenity, Precidia provides the perfect setting for all of life’s moments. ',
    },
    banner3: {
      text: 'There’s a reason adventure seekers travel from near and far to explore the enchanting backyard of West Coquitlam. ',
    },
    map: {
      categories: {
        shopping: 'Shopping',
        parks: 'Parks & Recreation',
        restaurants: 'Restaurants & Cafes',
        schools: 'Schools',
        projects: 'LEDMAC PROJECTS',
      },
    },
  },
  gallery: {
    hero: {
      title: (
        <>
          Ledingham McAllister’s <br />
          Next Vision for West Coquitlam
        </>
      ),
      caption: 'auto court',
    },
    banner1: {
      title: (
        <>
          unparalleled
          <br /> living experience
        </>
      ),
    },
    banner2: {
      title: (
        <>
          Come home to <br className="hide-on-mobile" />
          elevated living at Precidia
        </>
      ),
    },
    banner3: {
      title: (
        <>
          Precidia is Perfectly Placed <br className="hide-on-mobile" />
          for Work, Wellness, and <br className="hide-on-mobile" />
          Everything in Between.
        </>
      ),
    },
  },
  developer: {
    hero: {
      title: 'CONFIDENCE.  QUALITY.  VALUE',
      body: 'At Ledingham McAllister we are dedicated to smart development by creating superior new home communities in great locations. For over a century it has been our commitment to exacting standards of design, environmental leadership, engineering, and construction that ensures homes of enduring quality and value. More than 18,000 homes later, we can proudly state that our past was built on it, and our future depends on it.',
    },
    companies: {
      ibi: {
        description:
          'IBI Group is now proudly a part of Arcadis. Arcadis Group is a technology-driven design firm, providing architecture, engineering, planning, systems, and technology services to their clients for nearly 50 years. With more than 2,700 professionals located in over 60 offices around the world, they create responsive, resilient spaces and smart urban environments by bringing the established capabilities from their Intelligence practice into their core Buildings and Infrastructure businesses. From high-rises to hospitals, and transit systems to schools, Arcadis Group shapes the way people live, work, move, learn, and heal in the cities of tomorrow.',
      },
      pwl: {
        description:
          'For 40 years, PWL Partnership’s team of landscape architects, urban designers, and planners have built a reputation for excellence across Canada, the USA, and Asia. Their office has five Partners, four Associates and a team of 30 landscape architects and designers. Based in Vancouver, PWL works hard to establish meaningful and lasting relationships with our client groups, project teams, and local stakeholders. The firm’s experience encompasses complex waterfronts, greenways, transit corridors, urban plazas, streetscapes, neighbourhood parks and parklets.',
      },
      themill: {
        description:
          'The Mill Design Group Inc. was founded in 2011 by interior designer, Janine Wilson.  Based in beautiful North Vancouver, the Mill Design Group Inc. specializes in Multi-Family residential design. With over 18 years of experience in the interior design industry, Janine and her amazing team work with developers to create interiors and home finishes that reflect the best of a neighbourhood while embodying the future buyer’s esthetics and values.  The Mill’s design belief is that interior finishes should be timeless and easy to manage, without sacrificing style and comfort.',
      },
    },
  },
  footer: {
    column1: {
      title: 'Contact Us',
      buttonLabel: 'Register Now',
    },
    column2: {
      title: 'Presentation Centre',
      text: (
        <>
          Open Daily from 12 - 5pm <br />
          (Closed Fridays)
          <br />
          #2701 – 699 Whiting Way, Coquitlam BC
        </>
      ),
      buttonLabel: 'Book Appointment',
    },
    column3: {
      title: 'Site Location',
    },
    marketing: 'marketing in association with',
    disclaimer:
      '*Prices and incentives are subject to change without notice. The 3% savings will be calculated on the purchase price net of GST and shown as credit on statement of adjustments upon completion. Please contact a Sales Representative for more information. The information and pricing contained in this promotional material is for illustration purposes only and is subject to change without notice. Maps and renderings are an artistic representation only. The developer reserves to the right to make modifications or substitutions should they be necessary. Offers can only be made by way of a disclosure statement. LM Precidia Homes Limited Partnership develops the quality residences at Precidia. E. & O.E',
    corporate: {
      title: 'Confidence. Quality. Value',
      text: 'At Ledingham McAllister, we are dedicated to creating quality homes in superior locations and providing our customers with long-term value. We are committed to the highest standards in everything we do. Our past was built on it, our future depends on it.',
      privacy: 'Privacy Policy',
    },
  },
  register: {
    title: 'Register Now',
    firstname: 'First Name *',
    lastname: 'Last Name *',
    email: 'Email Address *',
    phone: 'Phone Number *',
    zip: 'Postal/Zip Code *',
    select: 'Select One...',
    buyRent: 'Are you looking to buy or rent? *',
    buyRentOptions: [
      {
        label: 'Buy',
        value: 'Buy',
      },
      {
        label: 'Rent',
        value: 'Rent',
      },
    ],
    howHeard: 'How did you hear about us? *',
    howHeardOptions: [
      {
        label: 'Online Ads',
        value: 'Online Ads',
      },
      {
        label: 'Chinese Ads',
        value: 'Chinese Ads',
      },
      {
        label: 'Korean Ads',
        value: 'Korean Ads',
      },
      {
        label: 'Print Advertising',
        value: 'Print Advertising',
      },
      {
        label: 'Social Media',
        value: 'Social Media',
      },
      {
        label: 'Signage',
        value: 'Signage',
      },
      {
        label: 'Realtor',
        value: 'Realtor',
      },
      {
        label: 'Friends & Family',
        value: 'Friends & Family',
      },
      {
        label: 'Other',
        value: 'Other',
      },
    ],
    whatInquire: 'What are you inquiring about?',
    whatInquireOptions: [
      {
        label: 'One bedroom',
        value: 'One bedroom',
      },
      {
        label: 'Two bedroom',
        value: 'Two bedroom',
      },
      {
        label: 'Three bedroom',
        value: 'Three bedroom',
      },
      {
        label: 'Penthouses',
        value: 'Penthouses',
      },
    ],
    realtor: 'Are you a realtor?*',
    workingWithRealtor: 'Are you working with a realtor?',
    yes: 'Yes',
    no: 'No',
    ifYesRealtor: `If you are a realtor or working with a realtor, please indicate your realtor's name or brokerage:`,
    disclaimer:
      'By checking this box, I express my consent to receive future e-communications from Ledingham McAllister, including information about upcoming developments, early access and special offers.*',
    submit: 'submit',
    required: '* Required field',
  },
  thankYou: {
    title: 'Thank You',
    text: 'Thank you for registering for Precidia by Ledingham McAllister. Your submission has been received and a representative will be in touch with you soon.',
  },
  notFound: {
    text: '404 - This page does not exist.',
  },
  privacy: {
    title: 'PRIVACY POLICY',
  },
};
